const shelf = {
  'module.shelf': '上架商品',
  'shelf.title.list': '上架商品',
  'shelf.title.add': '新增上架商品',
  'shelf.title.edit': '修改上架商品',
  'shelf.title.view': '上架商品詳情',
  'shelf.session.product': '商品圖示',
  'shelf.session.shipping': '物流資訊',
  'shelf.field.name': '商品名稱',
  'shelf.field.product': '關聯庫存商品',
  'shelf.field.orderPrice': '商品定價',
  'shelf.field.salesPrice': '商品促銷價',
  'shelf.field.summary': '商品特色',
  'shelf.field.spec': '商品規格',
  'shelf.field.detail': '詳細說明',
  'shelf.field.isPrivate': '隱藏商品',
  'shelf.field.url': '商品連結',
  'shelf.field.freeShippingPrice': '免運費門檻金額',
  'shelf.field.shippingFeeFami': '全家運費',
  'shelf.field.shippingFeeUnimart': '7-11運費',
  'shelf.field.shippingFeeHilife': '萊爾富運費',
  'shelf.field.shippingFeeD2d': '宅配運費',
}

export default shelf
