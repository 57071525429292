export function getStatusColor(status) {
  switch (status) {
    case 'ACTIVE':
    case 'REFUNDED':
      return 'var(--color-success-1)'
    case 'PENDING':
    case 'REFUNDING':
      return 'var(--color-warning-1)'
    default:
      return 'var(--color-dark-0)'
  }
}
