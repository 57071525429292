const order = {
  'module.order': '訂單',
  'ecpay.refundFailed': '退款失敗！',
  'order.title.list': '訂單目錄',
  'order.title.edit': '修改訂單',
  'order.title.view': '訂單詳情',
  'order.section.receipt': '發票資訊',
  'order.section.payment': '付款紀錄',
  'order.section.shipping': '出貨紀錄',
  'order.section.refund': '退款紀錄',
  'order.section.return': '退貨紀錄',
  'order.field.orderStatus': '訂單狀態',
  'order.field.receiptId': '統一編號',
  'order.field.receiptName': '發票抬頭',
  'order.field.receiptEmail': '發票收取E-mail',
  'order.field.payStatus': '付款狀態',
  'order.field.shipStatus': '出貨狀態',
  'order.field.shipMethod': '配送方式',
  'order.field.shipName': '收件人姓名',
  'order.field.shipCellphone': '收件人手機',
  'order.field.shipAddress': '收件人地址',
  'order.field.cvsName': '超取門市',
  'order.field.cvsPaymentNo': '超取支付編號',
  'order.field.amount': '單價',
  'order.field.subTotal': '小記',
  'order.field.orderAmount': '商品金額',
  'order.field.discountAmount': '折扣金額',
  'order.field.discountCodes': '折扣碼',
  'order.field.shippingAmount': '運費',
  'order.field.billedAmount': '總金額',
  'order.field.paidAmount': '已支付金額',
  'order.field.refundedAmount': '已退款金額',
  'order.field.channelProvider': '付款商',
  'order.field.transNo': '交易號',
  'order.orderStatus.PENDING': '待處理',
  'order.orderStatus.ACTIVE': '已成立',
  'order.orderStatus.INACTIVE': '已取消',
  'order.orderStatus.REFUNDED': '已退款',
  'order.orderStatus.REFUNDING': '申請退款',
  'order.payStatus.ACTIVE': '成功',
  'order.payStatus.INACTIVE': '失敗',
  'order.payStatus.PENDING': '待支付',
  'order.refundStatus.ACTIVE': '成功',
  'order.refundStatus.INACTIVE': '取消',
  'order.refundStatus.PENDING': '待處理',
  'order.shipMethod.FAMI': '全家超取',
  'order.shipMethod.FAMIC2C': '全家超取',
  'order.shipMethod.HILIFE': '萊爾富超取',
  'order.shipMethod.HILIFEC2C': '萊爾富超取',
  'order.shipMethod.UNIMART': '7-11超取',
  'order.shipMethod.UNIMARTC2C': '7-11超取',
  'order.shipMethod.D2D': '宅配',
  'orderRefund.title.add': '新增退款',
  'orderRefund.field.amount': '退款金額',
  'orderShip.title.add': '新增物流狀態',
  'orderShip.field.updatedAt': '更新時間',
  'orderShip.field.message': '物流狀態',
}

export default order
